import React, { forwardRef } from "react";
import MissingMascot from "../images/missing-mascot.png";

export default forwardRef((props, ref) => {
  return props.mascot && props.mascot.image ? (
    <img alt="Mascot" className="mascot-image" src={props.mascot.image} ref={ref} style={props.style} />
  ) : (
    <img alt="Missing Mascot" className="mascot-image" src={MissingMascot} ref={ref} style={props.style} />
  );
});
