import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import MascotImage from "../components/MascotImage";
import { useConversations } from "../hooks/useConversations.js";
import { useAuth } from "../hooks/useAuth";
import { client } from "../services/api";

const initialFormState = {
  firstName: "",
  lastName: "",
  email: "",
};

const sheetSchema = Yup.object().shape({
  firstName: Yup.string().trim().required("First Name required"),
  lastName: Yup.string().trim().required("Last Name required"),
  email: Yup.string()
    .required("Email Address is required")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Please enter a valid email address"),
});

export default function MascotChatAuth() {
  const { mascot } = useConversations();
  const { guestLogIn } = useAuth();
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [guestId, setGuestId] = useState(null);
  const [verify, setVerify] = useState(false);
  const [verificationError, setVerificationError] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");

  let { handleChange, handleSubmit, values, errors, touched } = useFormik({
    initialValues: initialFormState,
    validationSchema: sheetSchema,
    onSubmit: handleSubmission,
  });

  async function handleSubmission(values) {
    setError(false);
    setSubmitting(true);
    const registerResult = await client.guestRegister({ ...values, mascotId: mascot._id });
    if (registerResult.ok) {
      if (registerResult.data.verify) {
        setGuestId(registerResult.data.guestId);
        setVerify(true);
      } else if (registerResult.data.guestId) {
        guestLogIn();
      }
    } else {
      setError(registerResult.data?.message || registerResult.problem);
    }
    setSubmitting(false);
  }

  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  async function handleSubmitVerification() {
    setVerificationError(false);
    if (verificationCode === '') {
      setVerificationError('Verification Code required');
      return;
    }
    const verificationResult = await client.guestVerify({ guestId, verificationCode });
    if (verificationResult.ok) {
      guestLogIn();
    } else {
      setVerificationError(verificationResult.data.message);
    }
  }

  const _handleVerificationKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmitVerification();
    }
  };

  return (
    <div className="mascot-wrapper">
      <div className="mascot-guest-auth-wrapper">
        <div className="mascot-image-wrapper">
          <MascotImage mascot={mascot._id && mascot}></MascotImage>
        </div>
        <div className="signin-form-wrapper">
          <div className="signin-form">
            <h4>Squatchy</h4>
            <p>Great Notion Brewmaster</p>

            {verify ? (
            <>
              <p className="small">
                Please verify the code.
              </p>
              <div className="input-group">
                <input
                  type="text"
                  name="verificationCode"
                  required
                  placeholder="Verification Code"
                  tabIndex="1"
                  autoComplete="do-not-autofill"
                  onKeyDown={_handleVerificationKeyDown}
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                />
                {verificationError ? <div className="error">{verificationError}</div> : null}
              </div>

              <button className="positive" onClick={handleSubmitVerification} tabIndex="2">
                Continue
              </button>
            </>
            ) : (
            <>
              <p className="small">
                Please sign in to access this mascot.
              </p>
              <div className="input-group">
                <input
                  type="text"
                  name="firstName"
                  required
                  value={values.firstName}
                  placeholder="First Name"
                  onChange={handleChange}
                  tabIndex="1"
                  autoComplete="do-not-autofill"
                />
                {errors.firstName && touched.firstName ? <div className="error">{errors.firstName}</div> : null}
              </div>

              <div className="input-group">
                <input
                  type="text"
                  name="lastName"
                  required
                  value={values.lastName}
                  placeholder="Last Name"
                  onChange={handleChange}
                  tabIndex="2"
                  autoComplete="off"
                />
                {errors.lastName && touched.lastName ? <div className="error">{errors.lastName}</div> : null}
              </div>

              <div className="input-group">
                <input
                  type="email"
                  name="email"
                  required
                  placeholder="Email address"
                  value={values.email}
                  onChange={handleChange}
                  tabIndex="3"
                  onKeyDown={_handleKeyDown}
                />
                {errors.email && touched.email ? <div className="error">{errors.email}</div> : null}
              </div>

              {error && <div className="error">{error}</div>}

              <button disabled={submitting} className="positive" onClick={handleSubmit} tabIndex="4">
                Continue
              </button>
            </>
            )}
          </div>

          <div className="terms-links">
            <small>By signing in, you agree to the WiseOx <a href="https://wiseox.com/legal/terms-of-service" className="meta" target="_blank" rel="noreferrer">Terms of Service</a></small>
          </div>
        </div>
      </div>
    </div>
  );
}
