import React, { useState } from "react";
import CheckBox from "../ui/CheckBox";
import Modal from "../components/Modal";

import ActionsImage from "../images/actions-image.png";
import { useEditMascot } from "../hooks/useEditMascot";

export default function EditMascotSettings() {
  const { mascotDraft, saveDraft } = useEditMascot();
  const [emailDomainsModalOpen, setEmailDomainsModalOpen] = useState(false);
  const [newDomain, setNewDomain] = useState("");

  const handleReversePromptChange = (e) => {
    saveDraft({
      ...mascotDraft,
      prompt_settings: {
        ...mascotDraft.prompt_settings,
        reverse_prompt: e.target.checked,
      },
    });
  };

  const handleReversePromptTextChange = (e) => {
    mascotDraft.prompt_settings.reverse_prompt_text = e.target.value;
    saveDraft(mascotDraft);
  };

  const handleTopicChange = (k, key) => (e) => {
    const newTopics = [...mascotDraft.topics];
    const topic = newTopics[k];
    topic[key] = e.target.value;
    mascotDraft.topics = newTopics;
    saveDraft(mascotDraft);
  };

  const handleInlineActionsChange = (e) => {
    saveDraft({
      ...mascotDraft,
      prompt_settings: {
        ...mascotDraft.prompt_settings,
        inline_actions: e.target.checked,
        action_info_enable: e.target.checked,
        action_quiz_enable: e.target.checked,
        action_creative_enable: e.target.checked,
      },
    });
  };

  const handleInlineActionsEnabledChange = (e, type) => {
    mascotDraft.prompt_settings["action_" + type + "_enable"] = e.checked;
    saveDraft(mascotDraft);
  };

  const handleAddTopic = () => {
    mascotDraft.topics.push({});
    saveDraft({
      ...mascotDraft,
      topics: mascotDraft.topics,
    });
  };

  const handleRemoveTopic = (k) => {
    mascotDraft.topics.splice(k, 1);
    saveDraft(mascotDraft);
  };

  const handlePublicChange = (e) => {
    mascotDraft.public = e.target.checked;
    saveDraft(mascotDraft);
  };

  const handleGuestAuthenticationChange = (e) => {
    mascotDraft.guestAuthentication = e.target.checked;
    saveDraft(mascotDraft);
  };

  const addDomain = () => {
    if (newDomain === "") return;
    if (!mascotDraft.guestAuthenticationDomains) {
      mascotDraft.guestAuthenticationDomains = [];
    }

    mascotDraft.guestAuthenticationDomains.push(newDomain);
    setNewDomain("");
    saveDraft(mascotDraft);
  };

  const removeDomain = (i) => {
    mascotDraft.guestAuthenticationDomains.splice(i, 1);
    saveDraft(mascotDraft);
  };

  return (
    <div>
      <div className="edit-mascot-header">
        <h1>Settings</h1>
      </div>

      <div className="content-wrapper full-width col-2">
        <div className="content-col">
          <h3>Prompt Actions</h3>
          <p>
            Prompt Actions are preset prompts that can be selected instead of typing a prompt. Each action requires a button label
            and a description, which should provide enough criteria to inform the mascot's behavior for the prompt.
          </p>
          <img className="actions-img" src={ActionsImage} alt="actions" />

          <button className="small" onClick={() => handleAddTopic()}>
            Add Action
          </button>

          <div className="topics-editor">
            {mascotDraft.topics &&
              mascotDraft.topics.map((topic, k) => {
                return (
                  <div className="topic-field" key={k} data-rel={k}>
                    <div className="icon-btn">
                      <i className="icon-close" onClick={() => handleRemoveTopic(k)}></i>
                    </div>

                    <div className="text-field-combo">
                      <input
                        type="text"
                        className="small"
                        placeholder="Button label"
                        value={topic.name}
                        onChange={handleTopicChange(k, "name")}
                      />
                      <input
                        type="text"
                        className="small"
                        placeholder="Prompt description"
                        value={topic.prompt}
                        onChange={handleTopicChange(k, "prompt")}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="content-col">
          <h3>Other Settings</h3>

          <div className="switch-wrapper">
            <label>Enable Public Mascot</label>
            <span className="meta small">
              This will make the mascot available to anyone who has the URL, QR Code, or access to where the mascot is embedded.
            </span>
            <div className="switch">
              <input type="checkbox" checked={mascotDraft.public} onChange={handlePublicChange} />
              <span></span>
              <span></span>
            </div>
          </div>

          {mascotDraft.public && (
            <div style={{ paddingLeft: '20px' }}>
              <div className="switch-wrapper">
                <label>Require Guest Sign In</label>
                <span className="meta small">
                  Guests will be required to provide their name and email address. They will receive a verification code in their email to sign in.
                </span>
                <div className="switch">
                  <input type="checkbox" checked={mascotDraft.guestAuthentication} onChange={handleGuestAuthenticationChange} />
                  <span></span>
                  <span></span>
                </div>
              </div>

              <button className="small outline" onClick={() => setEmailDomainsModalOpen(true)}>
                Configure Email Domains
              </button>
            </div>
          )}

          <div className="input-group">
            <div className="switch-wrapper">
              <label>Mascot Greeting</label>
              <span className="meta small">Have the mascot greet the user when a new chat is started.</span>
              <div className="switch">
                <input
                  type="checkbox"
                  checked={(mascotDraft.prompt_settings && mascotDraft.prompt_settings.reverse_prompt) || ""}
                  onChange={handleReversePromptChange}
                />
                <span></span>
                <span></span>
              </div>
            </div>

            {mascotDraft.prompt_settings && mascotDraft.prompt_settings.reverse_prompt && (
              <div className="reverse-prompt-text">
                <input
                  type="text"
                  className="small"
                  placeholder="What would you like the mascot to say?"
                  value={mascotDraft.prompt_settings.reverse_prompt_text}
                  onChange={handleReversePromptTextChange}
                />
              </div>
            )}
          </div>

          <div className="input-group">
            <div className="switch-wrapper">
              <label>Inline Actions</label>
              <span className="meta small">
                A set of actions that can be performed for each line of text in the mascot's response.
              </span>
              <div className="switch">
                <input
                  type="checkbox"
                  checked={(mascotDraft.prompt_settings && mascotDraft.prompt_settings.inline_actions) || ""}
                  onChange={handleInlineActionsChange}
                />
                <span></span>
                <span></span>
              </div>
            </div>

            {mascotDraft.prompt_settings && mascotDraft.prompt_settings.inline_actions && (
              <div className="inline-action-options">
                <div className="checkbox-wrapper">
                  <CheckBox
                    checked={(mascotDraft.prompt_settings && mascotDraft.prompt_settings.action_info_enable) || ""}
                    onChange={(e) => handleInlineActionsEnabledChange(e, "info")}
                  />
                  <label>"Tell me more" - Provide more info on the response</label>
                </div>
                <div className="checkbox-wrapper">
                  <CheckBox
                    checked={(mascotDraft.prompt_settings && mascotDraft.prompt_settings.action_quiz_enable) || ""}
                    onChange={(e) => handleInlineActionsEnabledChange(e, "quiz")}
                  />
                  <label>"Quiz me" - Quiz the user about the response</label>
                </div>
                <div className="checkbox-wrapper">
                  <CheckBox
                    checked={(mascotDraft.prompt_settings && mascotDraft.prompt_settings.action_creative_enable) || ""}
                    onChange={(e) => handleInlineActionsEnabledChange(e, "creative")}
                  />
                  <label>"Be creative" - Write something creative based on the response</label>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal title="Email Domains Safelist" size="small" isOpen={emailDomainsModalOpen} close={() => setEmailDomainsModalOpen(false)}>
        <p>Add the domain(s)</p>

        <div className="input-group">
          <label>Enter Domains</label>
          <div className="input-combo over">
            <input
              className="small"
              type="text"
              value={newDomain}
              placeholder="e.g. mydomain.com"
              onChange={(e) => setNewDomain(e.target.value)}
            ></input>
            <button className="small" onClick={addDomain}>
              Add
            </button>
          </div>
        </div>

        {mascotDraft.guestAuthenticationDomains && mascotDraft.guestAuthenticationDomains.length > 0 && (
          <>
            <span className="meta">Domains Added</span>
            <div className="list">
              <ul>
                {mascotDraft.guestAuthenticationDomains &&
                  mascotDraft.guestAuthenticationDomains.map((d, index) => (
                    <li className="list-item clickable small solid" key={index}>
                      <div className="list-item-name">{d}</div>
                      <div className="list-item-actions">
                        <i className="icon-close" onClick={() => removeDomain(index)}></i>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
}
