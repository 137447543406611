import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import MascotImage from "./MascotImage";
import classNames from "classnames";
import Popover from "./Popover";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { client } from "../services/api";
import { servicesClient } from "../services/servicesApi";
import Modal from "./Modal";

export function MascotList({ mascots, fetchMascots }) {
  const [showOptionsPopover, setShowOptionsPopover] = useState({});
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const history = useHistory();
  const [disabledText, setDisabled] = useState("");
  const archiveInputRef = useRef();

  const archive = async () => {
    if (archiveInputRef.current?.value === "archive") {
      await client.archiveMascot(showArchiveModal);
    }
    setShowArchiveModal(false);
    toggleShowOptionsPopover(showArchiveModal);
    fetchMascots();
  };

  const toggleShowOptionsPopover = (mascotId) => {
    showOptionsPopover[mascotId] = !!!showOptionsPopover[mascotId];
    setShowOptionsPopover({ ...showOptionsPopover });
  };

  const cloneMascot = async (mascot) => {
    await servicesClient.duplicateMascot(mascot._id);
    fetchMascots();
  };

  const goTo = (url) => {
    history.push(url);
  };

  return (
    <div className="mascot-list">
      {mascots
        .slice(0)
        .reverse()
        .map((mascot) => {
          return (
            <div className={classNames("mascot-list-item", !mascot.image && "empty")} key={mascot._id}>
              <div className="icon-btn neutral" onClick={() => toggleShowOptionsPopover(mascot._id)}>
                <i className="icon-ellipses"></i>

                {showOptionsPopover[mascot._id] && (
                  <Popover close={() => toggleShowOptionsPopover(mascot._id)} className="right options-popover">
                    <div className="popover-item" onClick={() => goTo(`/mascot/${mascot._id}/chat`)}>
                      <i className="icon-info"></i>
                      View Mascot
                    </div>
                    <div className="popover-item" onClick={() => goTo(`/mascot/${mascot._id}/edit/info`)}>
                      <i className="icon-info"></i>
                      Edit Mascot
                    </div>

                    <div className="popover-item" onClick={() => cloneMascot(mascot)}>
                      <i className="icon-external-link"></i>
                      Make duplicate
                    </div>

                    <div className="popover-item danger" onClick={() => setShowArchiveModal(mascot._id)}>
                      <i className="icon-warn"></i>
                      Archive Mascot
                    </div>
                  </Popover>
                )}
              </div>
              <Link to={`/mascot/${mascot._id}/chat`}>
                <div className="mascot-list-item-info">
                  <MascotImage mascot={mascot}></MascotImage>

                  <h2>{mascot.name}</h2>
                  <p>{mascot.description}</p>

                  <div className="stats">
                    <div className="stat-label">{mascot.countSessions || "0"} Sessions</div>
                    <div className="stat-label">
                      {mascot.countUsers || "0"} {mascot.countUsers === 1 ? "User" : "Users"}
                    </div>
                  </div>
                </div>

                {mascot.image ? (
                  <div className="mascot-list-item-bg" style={{ backgroundImage: `url(${mascot.image})` }}></div>
                ) : (
                  <div className="mascot-list-item-bg empty"></div>
                )}
              </Link>

              <Modal
                title="Archive Mascot"
                size="small"
                isOpen={!!showArchiveModal}
                close={() => setShowArchiveModal(false)}
                action={
                  <button onClick={archive} className="danger large wide" disabled={disabledText !== "archive"}>
                    Archive
                  </button>
                }
              >
                <p>Archiving a mascot will make it publicly unavailable and inaccessible in your account.</p>
                <p>If you want to unarchive this mascot at any point, please contact us.</p>

                <div className="input-group">
                  <label>Enter “archive” to submit</label>
                  <input
                    onKeyDown={(e) => {
                      if (e.keyCode === 32) e.preventDefault();
                    }}
                    onChange={(e) => setDisabled(e.target.value)}
                    ref={archiveInputRef}
                    type="text"
                    defaultValue=""
                    placeholder="archive"
                  />
                </div>
              </Modal>
            </div>
          );
        })}
    </div>
  );
}
